import Cookies from 'js-cookie'
import { getBaseHostname } from '@criptalia/sdk/src/utils/translatorUtils'
import { isFunction, isString } from 'lodash'

const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

const PARAMS_TO_COOKIE = [
  { param: 'ref', cookie: 'evenfire' },
  { param: 'grsf', cookie: 'growsurf' },
  { param: 'custom', cookie: 'performoney' },
  { param: 'referralId', cookie: 'ucc-referral-id' },
  { param: 'utm_source', cookie: 'utm_source' },
  { param: 'utm_medium', cookie: 'utm_medium' },
  { param: 'utm_campaign', cookie: 'utm_campaign' },
  { param: 'utm_term', cookie: 'utm_term' },
  { param: 'utm_content', cookie: 'utm_content' },
  {
    param: 's_id',
    cookie: 'financeads',
    match: (params) => {
      let value = params.get('utm_source')
      if (!isString(value)) {
        return false
      }

      return value.includes('financeads')
    },
  },
]

export default function parseReferralCookies(refUrl) {
  // Determine the current or provided URL and parse it
  let currentUrl = refUrl ? new URL(refUrl) : window.location
  const params = new URLSearchParams(currentUrl.search)
  let newUrl = null

  PARAMS_TO_COOKIE.filter(({ param }) => params.has(param)).forEach(
    ({ param, cookie, match }) => {
      // Check match function given in cookie list.
      if (isFunction(match)) {
        if (!match(params)) {
          return
        }
      }
      // There may be the old domain-specific cookie; we remove it to avoid duplicates
      Cookies.remove(cookie, params.get(param))
      Cookies.set(cookie, params.get(param), { domain: getBaseHostname() })
    },
  )

  return newUrl
}
