import { User, UserData, UserMeta } from "./user/user.types"
import { UserDataValidation } from "@criptalia/sdk/src/wizard/wizard.types"
import { Lang } from "@criptalia/sdk/src/utils/translator"

export class Sdk {
  static instance = null
  static initialized = false

  _config: SdkConfig = {
    data: {
      user: null,
      userData: null,
      userDataValidation: null,
      userMeta: null,
      reloadUser: null,
      reloadUserMeta: null,
    },
    api: {
      baseUrl: '',
      endpoints: {},
    },
    variables: {
      lang: {
        dev: ['it', 'en', 'es', 'ca', 'pt'] satisfies Array<Lang>,
        prod: ['it', 'en', 'es'] satisfies Array<Lang>,
      },
    },
    env: { isProduction: null, datocmsToken: null },
  }

  static getInstance() {
    if (Sdk.instance === null) {
      Sdk.instance = new Sdk()
    }
    return Sdk.instance
  }

  constructor() {
    if (Sdk.instance !== null) {
      return Sdk.getInstance()
    }
    Sdk.instance = this
  }

  static checkSdkInitialized() {
    if (!Sdk.getInstance().initialized) {
      throw Error(
        'SDK must be configured before use. Please run Sdk.init() in your index file passing the environment configuration.',
      )
    }
  }

  static getConfig(strict?: boolean): SdkConfig {
    if (strict) {
      Sdk.checkSdkInitialized()
    }
    return Sdk.getInstance()._config
  }

  static getUser(): User {
    return Sdk.getConfig(true).data.user
  }

  static init(configOverrides: Partial<SdkConfig> = {}): Promise<SdkConfig> {
    Sdk.setConfig({ ...Sdk.getConfig(), ...configOverrides })
    return new Promise((resolve) => {
      Sdk.getInstance().initialized = true
      resolve(Sdk.getConfig(true))
    })
  }

  static getUserData(): UserData {
    return Sdk.getConfig(true).data.userData
  }

  static getUserMeta(): UserMeta {
    return Sdk.getConfig(true).data.userMeta
  }

  static getUserDataValidation(): UserDataValidation {
    return Sdk.getConfig(true).data.userDataValidation
  }

  static reloadUser() {
    return Sdk.getConfig(true).data.reloadUser()
  }

  static reloadUserMeta() {
    return Sdk.getConfig(true).data.reloadUserMeta()
  }

  static setConfig(config) {
    Sdk.getInstance()._config = config
  }

  static saveUser(user: User) {
    Sdk.setConfig({
      ...Sdk.getConfig(true),
      data: {
        ...Sdk.getConfig(true).data,
        user,
      },
    })
  }

  static saveUserData(userData: UserData) {
    Sdk.setConfig({
      ...Sdk.getConfig(true),
      data: {
        ...Sdk.getConfig(true).data,
        userData,
      },
    })
  }

  static saveUserDataValidation(userDataValidation: UserDataValidation) {
    Sdk.setConfig({
      ...Sdk.getConfig(true),
      data: {
        ...Sdk.getConfig(true).data,
        userDataValidation,
      },
    })
  }

  static saveUserMeta(userMeta: UserMeta) {
    Sdk.setConfig({
      ...Sdk.getConfig(true),
      data: {
        ...Sdk.getConfig(true).data,
        userMeta,
      },
    })
  }

  static saveUserReloadFunction(fn) {
    Sdk.setConfig({
      ...Sdk.getConfig(true),
      data: {
        ...Sdk.getConfig(true).data,
        reloadUser: fn,
      },
    })
  }

  static saveUserMetaReloadFunction(fn) {
    Sdk.setConfig({
      ...Sdk.getConfig(true),
      data: {
        ...Sdk.getConfig(true).data,
        reloadUserMeta: fn,
      },
    })
  }
}

export type SdkConfig = {
  variables: {
    lang: {
      dev: Array<string>
      prod: Array<string>
    }
  }
  data: {
    user: User
    userData: UserData
    userDataValidation: UserDataValidation
    userMeta: UserMeta
    reloadUser: () => Promise<void>
    reloadUserMeta: () => Promise<void>
  }
  api: {
    baseUrl: string
    endpoints: Partial<{
      V1: string
      V2: string
      V3: string
      GonkingAdmin: string
      Gooser: string
      GooserAdmin: string
      CampaignManagerAdmin: string
      Translations: string
      TallyAdmin: string
      CampaignoneAdmin: string
      CampaignUserAdmin: string
      MuserAdmin: string
    }>
  }
  env: Record<string, any> &
    Partial<{
      isProduction: boolean
      isProductionBuild: boolean
      websocketUrl: string
      mapsApiKey: string
    }>
}
