import { WEBFLOW_BASE_URL } from '@src/consts'
import { FRONTEND_ENV } from '@src/env'

export const externalUrls = {
  '/': {
    it: WEBFLOW_BASE_URL + '/it',
    en: WEBFLOW_BASE_URL + '/',
    es: WEBFLOW_BASE_URL + '/es',
  },
  '/company': {
    it: WEBFLOW_BASE_URL + '/it/per-aziende',
    en: WEBFLOW_BASE_URL + '/en/for-companies',
    es: WEBFLOW_BASE_URL + '/es/para-empresas',
  },
  '/blog': {
    it: WEBFLOW_BASE_URL + '/it/blog',
    en: WEBFLOW_BASE_URL + '/en/blog',
    es: WEBFLOW_BASE_URL + '/es/blog',
  },
  '/about': {
    it: WEBFLOW_BASE_URL + '/it/chi-siamo',
    en: WEBFLOW_BASE_URL + '/en/about-us',
    es: WEBFLOW_BASE_URL + '/es/sobre-nosotros',
  },
  '/faqs/company': {
    it: WEBFLOW_BASE_URL + '/it/faqs-aziende',
    en: WEBFLOW_BASE_URL + '/en/faqs-companies',
    es: WEBFLOW_BASE_URL + '/es/faqs-empresas',
  },
  '/faqs/investor': {
    it: 'https://helpdesk.evenfi.com/it/',
    en: 'https://helpdesk.evenfi.com/it/',
    es: 'https://helpdesk.evenfi.com/es/',
  },
}

export default function externalRedirect(lang, refUrl) {
  let pathname = window.location.pathname
  if (refUrl) {
    pathname = new URL(refUrl).pathname
  }

  // External redirects are for production only, to have easier access to dev
  // (React will re-route to login page)
  if (!FRONTEND_ENV.isProduction) {
    return null
  }

  const destination = externalUrls[pathname]
  if (!destination || !destination[lang]) {
    return null
  }

  return destination[lang]
}
