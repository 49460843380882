import { SdkConfig } from '@criptalia/sdk/src/Sdk'

const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://'

const websocketUrl = wsProtocol + window.location.host + '/connection/websocket'

const isProduction = window.location.host.endsWith('evenfi.com')
export const FRONTEND_ENV: SdkConfig['env'] = {
  isProduction,
  isProductionBuild: import.meta.env.PROD,
  websocketUrl,
  brandName: 'EvenFi',
  datocmsToken:
    import.meta.env.VITE_DATO_CMS_TOKEN || '120e376c3c2da3c3d867ca8fc16aa4',
  mapsApiKey:
    import.meta.env.VITE_MAPS_API_KEY ||
    'AIzaSyB15ds5CBvNKu8VGdxMxKp7nVPPLYtqQkM',
}
