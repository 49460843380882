export const VALIDATION_STATUS = {
  // Field not alredy been validated
  NOT_SPECIFIED: 'NOT_SPECIFIED',
  // Field in validation
  VALIDATION_IN_PROGRESS: 'VALIDATION_IN_PROGRESS',
  // Field validation OK!
  VALIDATED: 'VALIDATED',
  // Invalid field, got message to explain why
  NOT_VALIDATED: 'NOT_VALIDATED',
}

export const WEBFLOW_BASE_URL = 'https://web.evenfi.com'
